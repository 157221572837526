.app {
  text-align: center;
  height: 100vh;
  /* Only iPhone */
  height: 100svh;

  /* Center vertically and horizontally */
  display: grid;
  justify-content: center;
  align-items: center;
}

.card {
  /* background-color: white; */
  width: 20rem;
  display: flex;
  flex-direction: column;
}

header {
  border-radius: 1rem 1rem 0 0;
  /* height: 50%; */

  position: relative;
  text-align: center;
  background-color: white;
  /* background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  ); */
  color: white;
}

main {
  background-color: white;
  margin-top: -1px; /*Safari issue*/
}

img.logo {
  height: 5rem;
  pointer-events: none;
  margin-top: 2rem;
}

.name {
  margin: 2rem 0 0rem 0;
  font-weight: 400;
  font-size: 2rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium",
    "游ゴシック", "Yu Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "メイリオ",
    Meiryo, sans-serif;
  color: #868585;
}

.ocupation {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: #868585;
}

.waves {
  position: relative;
  width: 99.99%;
  height: 40px;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 40px;
  max-height: 40px;
}

.cardFooter {
  background-color: #e4f0fc;
  position: relative;
  /* height: 50%; */
  border-radius: 0 0 1rem 1rem;
  padding: 2rem 0 2rem 0;

  display: flex;
  flex-direction: column;
}

.main {
  background-color: #fff;
  position: relative;
  border-radius: 0 0 1rem 1rem;
  padding: 2rem;
  margin-top: -1px;

  display: flex;
  flex-direction: column;
}

.qrcode {
  height: 15rem;
}

button {
  font-family: inherit;
  font-size: 20px;
  color: white;
  padding: 0.7em 1em;
  text-align: left;
  padding-left: 4.2rem;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  width: 15rem;
  margin: 0.4rem 0 0.4rem 0;
  opacity: 1;
}

button:hover {
  opacity: 0.8;
  cursor: pointer;
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.email {
  background: #085c9a;
}
.whatsapp {
  background-color: #033162;
}
.website {
  background-color: #011254;
}

.sns {
  margin-left: 0.5rem;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  /* .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  } */
}
