@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");
@import url("https://fonts.googleapis.com/earlyaccess/hannari.css");

body {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(4, 97, 159, 1) 100%
  );
  background-repeat: no-repeat;
  margin: 0;
  height: 100%;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium",
    "游ゴシック", "Yu Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "メイリオ",
    Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
